// Docs at https://github.com/vinissimus/next-translate
module.exports = {
  locales: ['sv', 'en'],
  defaultLocale: 'sv',
  // Moved locales to root due to:
  // https://github.com/vinissimus/next-translate/issues/741#issuecomment-1145838941
  // https://github.com/vinissimus/next-translate/issues/851
  // loadLocaleFrom: (lang, ns) =>
  //   import(`./src/locales/${lang}/${ns}.json`).then((m) => m.default),
  pages: {
    '*': ['common', 'articles', 'search', 'countries'],
  },
};
